/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#DigitalMarketing {
    padding: 0;
    width: 100%;
    height: 80vh;
    margin-top: 5%;
    position: relative;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    /* background: url("https://www.wecode-inc.com/img/cloud-computing-development-banner.png") no-repeat; */
    /* width: 100%;
    height: 80vh;
    position: relative;
    background-size: cover;
    padding: 0;
    margin-top: 5%; */
}

#DigitalMarketing .hero-DigitalMarketing {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    /* z-index: 10; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

#DigitalMarketing .hero-DigitalMarketing h2 {
    /* color: #0c2e8a; */
    color: white;
    margin-bottom: 30px;
    font-size: 64px;
    font-weight: 700;
}

#DigitalMarketing .hero-DigitalMarketing h2 span {
    color: #50d8af;
    text-decoration: underline;
}

@media (max-width: 767px) {
    #DigitalMarketing .hero-DigitalMarketing h2 {
        font-size: 34px;
    }
}

#DigitalMarketing .hero-DigitalMarketing .btn-get-started,
#hero .hero-DigitalMarketing .btn-projects {
    font-family: "Raleway", sans-serif;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 1px;
    display: inline-block;
    padding: 10px 32px;
    border-radius: 2px;
    transition: 0.5s;
    margin: 10px;
    color: #fff;
}

#DigitalMarketing .hero-DigitalMarketing .btn-get-started {
    background: #0c2e8a;
    border: 2px solid #0c2e8a;
}

#DigitalMarketing .hero-DigitalMarketing .btn-get-started:hover {
    background: none;
    color: #0c2e8a;
}

#DigitalMarketing .hero-DigitalMarketing .btn-projects {
    background: #50d8af;
    border: 2px solid #50d8af;
}

#DigitalMarketing .hero-DigitalMarketing .btn-projects:hover {
    background: none;
    color: #50d8af;
}

#DigitalMarketing .hero-slider {
    /* z-index: 8; */
    height: 60vh;
}

#DigitalMarketing .hero-slider::before {
    content: "";
    background-color: rgba(255, 255, 255, 0.7);
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    /* z-index: 7; */
}

#DigitalMarketing .hero-slider .swiper-slide {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition-property: opacity;
}


/*--------------------------------------------------------------
# DigitalMarketing
--------------------------------------------------------------*/



#DigitalContain {
    padding: 60px 0 30px 0;
}

#DigitalContain .about-img {
    overflow: hidden;
}

#DigitalContain .about-img img {
    margin-left: -15px;
    max-width: 100%;
}

@media (max-width: 768px) {

    #DigitalContain .about-img {
        height: auto;
    }

    #DigitalContain .about-img img {
        margin-left: 0;
        padding-bottom: 30px;
    }
}

#DigitalContain .content h2 {
    color: #0c2e8a;
    font-weight: 700;
    font-size: 36px;
    font-family: "Raleway", sans-serif;
}


#DigitalContain .content p {
    line-height: 26px;
}

#DigitalContain .content p:last-child {
    margin-bottom: 0;
}

#DigitalContainText {
    font-family: var(--global-body-font-family);
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: #37000A;
    line-height: 1.6;

    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}


#DigitalAppImage {
    border-radius: 15px 15px 15px 15px;
    box-shadow: 0 0 10px 0 rgb(0 0 0 / 50%);
    margin-left: 30%;
    margin-top: 7%;
    width: 75%;
    /* height: 100%; */
}



@media (max-width: 768px) {
    #DigitalAppImage {

        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
    }
}