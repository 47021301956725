#portfolio {
  width: 100%;
  height: 70vh;
  position: relative;
  background: url("https://www.bleepstatic.com/content/hl-images/2019/10/28/programming-header.jpg") no-repeat;
  background-size: cover;
  padding: 0;
  margin-top: 5%;
}

#portfolio .hero-content {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  /* z-index: 10; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

#portfolio .hero-content h2 {
  /* color: #0c2e8a; */
  color: white;
  margin-bottom: 30px;
  font-size: 64px;
  font-weight: 700;
}

#portfolio .hero-content h2 span {
  color: #50d8af;
  text-decoration: underline;
}

@media (max-width: 767px) {
  #portfolio .hero-content h2 {
    font-size: 34px;
  }
}

#portfolio .hero-content .btn-get-started,
#hero .hero-content .btn-projects {
  font-family: "Raleway", sans-serif;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 32px;
  border-radius: 2px;
  transition: 0.5s;
  margin: 10px;
  color: #fff;
}

#portfolio .hero-content .btn-get-started {
  background: #0c2e8a;
  border: 2px solid #0c2e8a;
}

#portfolio .hero-content .btn-get-started:hover {
  background: none;
  color: #0c2e8a;
}

#portfolio .hero-content .btn-projects {
  background: #50d8af;
  border: 2px solid #50d8af;
}

#portfolio .hero-content .btn-projects:hover {
  background: none;
  color: #50d8af;
}

#portfolio .hero-slider {
  /* z-index: 8; */
  height: 60vh;
}

#portfolio .hero-slider::before {
  content: "";
  background-color: rgba(255, 255, 255, 0.7);
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  /* z-index: 7; */
}

#portfolio .hero-slider .swiper-slide {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition-property: opacity;
}

/*--------------------------------------------------------------
# Our Services
--------------------------------------------------------------*/

.services .icon-box {
  text-align: center;
  border: 1px solid #ededed;
  padding: 80px 20px;
  transition: all ease-in-out 0.3s;
  /* background-color: #50d8af; */
  box-shadow: 0 4px 8px 0 orangered, 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.services .icon-box .icon {
  margin: 0 auto;
  width: 64px;
  height: 64px;
  background: #e96b56;
  border-radius: 5px;
  transition: all 0.3s ease-out 0s;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  transform-style: preserve-3d;
}

.services .icon-box .icon i {
  color: #fff;
  font-size: 28px;
}

.services .icon-box .icon::before {
  position: absolute;
  content: "";
  left: -8px;
  top: -8px;
  height: 100%;
  width: 100%;
  background: #fbe2dd;
  border-radius: 5px;
  transition: all 0.3s ease-out 0s;
  transform: translateZ(-1px);
}

.services .icon-box h4 {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 24px;
}

.services .icon-box h4 a {
  color: #545454;
}

.services .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.services .icon-box:hover {
  background: #e96b56;
  /* border-radius: 50px; */
  border-color: #e96b56;
  /* width: 64px;
    height: 64px; */
}

.services .icon-box:hover .icon {
  background: #fff;
}

.services .icon-box:hover .icon i {
  color: #e96b56;
}

.services .icon-box:hover .icon::before {
  background: #ef9383;
}

.services .icon-box:hover h4 a,
.services .icon-box:hover p {
  color: #fff;
}

/*--------------------------------------------------------------
# Industries
--------------------------------------------------------------*/

/*--------------------------------------------------------------
# Specials
--------------------------------------------------------------*/
.specials {
  overflow: hidden;
  /* margin-left: -3%; */
}

.specials .nav-tabs {
  border: 0;
}

.specials .nav-link {
  border: 0;
  /* padding: 12px 15px 12px 0; */
  transition: 0.3s;
  color: #433f39;
  border-radius: 0;
  border-right: 2px solid #e8e7e4;
  font-weight: 600;
  font-size: 15px;
}

.specials .nav-link:hover {
  color: #ffb03b;
}

.specials .nav-link.active {
  color: #ffb03b;
  border-color: #ffb03b;
}

.specials .tab-pane.active {
  -webkit-animation: fadeIn 0.5s ease-out;
  animation: fadeIn 0.5s ease-out;
}

.specials .details h3 {
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #433f39;
}

.specials .details p {
  color: #777777;
}

.specials .details p:last-child {
  margin-bottom: 0;
}

@media (max-width: 992px) {
  .specials .nav-link {
    border: 0;
    padding: 15px;
  }

  .specials .nav-link.active {
    color: #fff;
    background: #ffb03b;
  }
}

.specials-title {
  text-align: center;
  padding-bottom: 30px;
}

.specials-title h2 {
  margin: 15px 0 0 0;
  font-size: 32px;
  font-weight: 700;
  color: #5f5950;
  font-family: "Satisfy", sans-serif;
}

/* .specials-title h2 span {
  color: #ffb03b;
} */
.specials-title p {
  margin: 15px auto 0 auto;
  font-weight: 300;
}

@media (min-width: 1024px) {
  .specials-title p {
    width: 50%;
  }
}

.events .specials-title h2 {
  color: #fff;
}

/*--------------------------------------------------------------
# PotflioServices
--------------------------------------------------------------*/


.PotflioServices .icon-box {
  padding: 50px 20px;
  margin-top: 35px;
  margin-bottom: 25px;
  text-align: center;
  border: 1px solid #ebe6e4;
  height: 150px;
  position: relative;
  background: #faf9f8;
}

.PotflioServices .icon {
  position: absolute;
  top: -36px;
  left: calc(50% - 36px);
  transition: 0.2s;
  border-radius: 50%;
  border: 6px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 72px;
  height: 72px;
  background: #002B4A;
}

.PotflioServices .icon i {
  color: #fff;
  font-size: 24px;
  line-height: 0;
}

.PotflioServices .title {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 18px;
  text-transform: uppercase;
}

.PotflioServices .title a {
  color: #343a40;
}

.PotflioServices .icon-box:hover .icon {
  background: #fff;
  border: 2px solid #002B4A;
}

.PotflioServices .icon-box:hover .icon i {
  color: #002B4A;
}

.PotflioServices .icon-box:hover .title a {
  color: #002B4A;
}

.PotflioServices .description {
  line-height: 24px;
  font-size: 14px;
}