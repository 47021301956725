
/* Box consulting service */

.consultingcenter {
  text-align: center;
  margin-top: 10px;
  font-size: 25px;
  margin-bottom: 35px;
  /* border: 3px solid green; */
}

.slow{
  margin-top: 50px;
}

.flow{
  margin-top: 50px;
}

#flip {
  border: 1px solid black;
  box-shadow: 0 0 3px #ff0000, 0 0 5px #0000ff;
  padding: 9px;
  /* margin: 0px; */
  /* margin-left: 13px; */
  justify-content: center;
}

.move{
  margin-top: 50px;
}





.flip-box {
  background-color: transparent;
  /* width: 300px; */
  height: 210px;
  border: 1px solid #f1f1f1;
  perspective: 1000px;
  margin-left: 50px;
}

.flip-box-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-box:hover .flip-box-inner {
  transform: rotateY(180deg);
}

.flip-box-front,
.flip-box-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-box-front {
  /* background-color: #bbb; */
  color: black;
  background: #fff;
  box-shadow: 0 0 29px 0 rgba(68, 88, 144, 0.295);
}

.flip-box-back {
  background-color: dodgerblue;
  color: white;
  transform: rotateY(180deg);
}

#Blocktext{
  font-size: 91%;
  font-weight: 500;
}
#Androidtext{
  margin-top: 12% !important;
}