/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#Product {
  padding: 0;
  height: 80vh;
  margin-top: 5%;
  /* width: 100%; */
  position: relative;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  /* background: url("https://static.vecteezy.com/system/resources/previews/003/554/120/original/modern-wavy-lines-paper-cut-style-yellow-color-banner-design-free-vector.jpg")no-repeat; */
  /* background-size: cover; */
}

.just{
  justify-content: center;
}

.red{
  font-size: 200px;
}

#Product .hero-Product {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  /* z-index: 10; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

#Product .hero-Product h2 {
  /* color: #0c2e8a; */
  color: white;
  margin-bottom: 30px;
  font-size: 64px;
  font-weight: 700;
}

#Product .hero-Product h2 span {
  color: #50d8af;
  text-decoration: underline;
}

@media (max-width: 767px) {
  #Product .hero-Product h2 {
    font-size: 34px;
  }
}

#Product .hero-Product .btn-get-started,
#hero .hero-Product .btn-projects {
  font-family: "Raleway", sans-serif;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 32px;
  border-radius: 2px;
  transition: 0.5s;
  margin: 10px;
  color: #fff;
}

#Product .hero-Product .btn-get-started {
  background: #0c2e8a;
  border: 2px solid #0c2e8a;
}

#Product .hero-Product .btn-get-started:hover {
  background: none;
  color: #0c2e8a;
}

#Product .hero-Product .btn-projects {
  background: #50d8af;
  border: 2px solid #50d8af;
}

#Product .hero-Product .btn-projects:hover {
  background: none;
  color: #50d8af;
}

#Product .hero-slider {
  /* z-index: 8; */
  height: 60vh;
}

#Product .hero-slider::before {
  content: "";
  background-color: rgba(255, 255, 255, 0.7);
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  /* z-index: 7; */
}

#Product .hero-slider .swiper-slide {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition-property: opacity;
}

/*--------------------------------------------------------------
# Product
--------------------------------------------------------------*/

#cardH {
  height: 100%;
  width: 60%;
}