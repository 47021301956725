#web {
  /* width: 100%;
  height: 70vh;
  position: relative;
  background: url("https://cdn3.notifyvisitors.com/blog/wp-content/uploads/2019/12/11071051/Introduction-to-Progressive-Web-Apps-on-desktop-banner1.jpg") ;
  background-size: cover;
  padding: 0;
  background-size: cover;
  padding: 0;
  margin-top: 5%; */

   padding: 0;
    width: 100%;
    height: 80vh;
    margin-top: 5%;
    position: relative;
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

#web .hero-content {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  /* z-index: 10; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

#web .hero-content h2 {
  color: white;
  margin-bottom: 30px;
  font-size: 64px;
  font-weight: 700;
}

@media (max-width: 767px) {
  #web .hero-content h2 {
    font-size: 34px;
  }
}


/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
#webabout {
  /* margin-top: 490px; */
  padding: 60px 0 30px 0;
}

#webabout .about-img {
  overflow: hidden;
}

#webabout .about-img img {
  margin-left: -15px;
  max-width: 100%;
}

@media (max-width: 768px) {
  #webabout .about-img {
    height: auto;
  }

  #webabout .about-img img {
    margin-left: 0;
    padding-bottom: 30px;
  }
}

#webabout .content h2 {
  color: #0c2e8a;
  font-weight: 700;
  font-size: 36px;
  font-family: "Raleway", sans-serif;
}

#webabout .content h3 {
  color: #555;
  font-weight: 300;
  font-size: 18px;
  line-height: 26px;
  font-style: italic;
}

#webabout .content p {
  line-height: 26px;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}


#webevelopmentImage {
  border-radius: 15px 15px 15px 15px;
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 50%);
  margin-left: 30%;
  margin-top: 7%;
}

@media (max-width: 768px) {
  #webevelopmentImage {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    /* padding-bottom: 30px; */
  }
}

