/* section {
  padding: 40px 0;
  overflow: hidden;
} */

#about {
  width: 100%;
  height: 70vh;
  position: relative;
  background: url("https://t4.ftcdn.net/jpg/03/17/34/01/360_F_317340177_JwtBM2DQKYuSHZgTZNPyg0fln6JZ1ybA.jpg") no-repeat;
  background-size: cover;
  padding: 0;
  margin-top: 5%;

}

#about .hero-content {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  /* z-index: 10; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

#about .hero-content h3 {
  color: white;
  margin-bottom: 30px;
  font-size: 34px;
  font-weight: 400;
}

#about .hero-content h2 span {
  color: #50d8af;
  text-decoration: underline;
}


@media (max-width: 767px) {
  #about .hero-content h2 {
    font-size: 34px;
  }
}

#about .hero-content .btn-get-started,
#hero .hero-content .btn-projects {
  font-family: "Raleway", sans-serif;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 32px;
  border-radius: 2px;
  transition: 0.5s;
  margin: 10px;
  color: #fff;
}

#about .hero-content .btn-get-started {
  background: orangered;
  border: 2px solid orangered;
  border-radius: 5px;
}

#about .hero-content .btn-get-started:hover {
  background: #0c2e8a;
  color: #fff;
  border: 2px solid #0c2e8a;
  text-decoration: none;
}

#about .hero-content .btn-projects {
  background: #50d8af;
  border: 2px solid #50d8af;
}

#about .hero-content .btn-projects:hover {
  background: none;
  color: #50d8af;
}

#about .hero-slider {
  /* z-index: 8; */
  height: 60vh;
}

#about .hero-slider::before {
  content: "";
  background-color: rgba(255, 255, 255, 0.7);
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  /* z-index: 7; */
}

#about .hero-slider .swiper-slide {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition-property: opacity;
}


/* Cart */

#about .carousel-item {
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

#about .carousel-item::before {
  content: "";
  background-color: rgba(12, 11, 10, 0.5);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 70vh;

}

#about .carousel-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#about .carousel-content {
  text-align: center;
}

#about h4 {
  color: #fff;
  margin-bottom: 30px;
  font-size: 28px;
  font-weight: 600;
}

#about h2 span {
  color: #ffb03b;
}

#about p {
  width: 80%;
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
  margin: 0 auto 30px auto;
  color: #fff;
}

#about .carousel-inner .carousel-item {
  transition-property: opacity;
  background-position: center top;
}

#about .carousel-inner .carousel-item,
#about .carousel-inner .active.carousel-item-start,
#about .carousel-inner .active.carousel-item-end {
  opacity: 0;
}

/*  */
#cardTextImage {
  display: flex;
  padding: 20px;
  margin-bottom: 20px;
  /* background-color: #1faadb; */
  justify-content: center;
}


@media (max-width: 768px) {
  /* #cardTextImage .about-img {
    height: auto;
  }

  #cardTextImage .about-img img {
    margin-left: 0;
    padding-bottom: 30px;
  } */
}

#cardTextImage .content h2 {
  color: #0c2e8a;
  font-weight: 700;
  font-size: 28px;
  font-family: "Raleway", sans-serif;
}

#cardTextImage .content h3 {
  color: #555;
  font-weight: 300;
  font-size: 18px;
  line-height: 26px;
  font-style: italic;
}

#cardTextImage .content p {
  line-height: 26px;
}

#cardTextImage .content p:last-child {
  margin-bottom: 0;
}

#cardTextImage .content i {
  font-size: 20px;
  padding-right: 4px;
  color: #50d8af;
}

#cardTextImage .content ul {
  list-style: none;
  padding: 0;
}

#cardTextImage .content ul li {
  padding-bottom: 10px;
}

/*  */
#dollarText {
  font-family: var(--global-body-font-family);
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #37000A;
  line-height: 1.6;

  /* margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px; */
}

#aboutImage {

  /* background-color: #0c2e8a; */
  margin-left: 47%;
  /* align-items: flex-end; */
  margin-top: 25%;
  /* display: flex;
  align-items: flex-end; */
  /* position: absolute;
  right: 0px; */

  /* margin-left: 11%; */
  /* align-items: stretch */
}


@media (max-width: 768px) {
  /* /* #cardTextImage .about-img {
    height: auto;
  } */

  #aboutImage {
    display: block;
    margin-left: auto;
    margin-right: auto;
    /* padding-bottom: 30px; */
  }
}


@media (max-width: 600px) {
  #aboutImage {
    display: none;
  }

}