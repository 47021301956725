/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#Careers {
  width: 100%;
  height: 70vh;
  position: relative;
  background: url("https://www.aon.com/getmedia/35b27165-c8d6-4777-8894-391089757995/careers-banner.PNG.aspx?width=850&height=265&ext=.png") no-repeat;
  background-size: cover;
  padding: 0;
  margin-top: 5%;
}

#Careers .hero-content {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  /* z-index: 10; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

#Careers .hero-content h2 {
  /* color: #0c2e8a; */
  color: white;
  margin-bottom: 30px;
  font-size: 64px;
  font-weight: 700;
}

#Careers .hero-content h2 span {
  color: #50d8af;
  text-decoration: underline;
}

@media (max-width: 767px) {
  #Careers .hero-content h2 {
    font-size: 34px;
  }
}

#Careers .hero-content .btn-get-started,
#hero .hero-content .btn-projects {
  font-family: "Raleway", sans-serif;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 32px;
  border-radius: 2px;
  transition: 0.5s;
  margin: 10px;
  color: #fff;
}

#Careers .hero-content .btn-get-started {
  background: #0c2e8a;
  border: 2px solid #0c2e8a;
}

#Careers .hero-content .btn-get-started:hover {
  background: none;
  color: #0c2e8a;
}

#Careers .hero-content .btn-projects {
  background: #50d8af;
  border: 2px solid #50d8af;
}

#Careers .hero-content .btn-projects:hover {
  background: none;
  color: #50d8af;
}

#Careers .hero-slider {
  /* z-index: 8; */
  height: 60vh;
}

#Careers .hero-slider::before {
  content: "";
  background-color: rgba(255, 255, 255, 0.7);
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  /* z-index: 7; */
}

#Careers .hero-slider .swiper-slide {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition-property: opacity;
}


/*--------------------------------------------------------------
# Careers
--------------------------------------------------------------*/
.Careers .info-box {
  color: #444444;
  text-align: center;
  box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
  padding: 20px 0 30px 0;
}

.Careers .info-box i {
  font-size: 32px;
  color: #e96b56;
  border-radius: 50%;
  padding: 8px;
  border: 2px dotted #fef5f4;
}

act .info-box p {
  padding: 0;
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.Careers .email-form {
  box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
  padding: 30px;
}

.Careers .email-form .validate {
  display: none;
  color: red;
  margin: 0 0 15px 0;
  font-weight: 400;
  font-size: 13px;
}

.Careers .email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

.Careers .email-form .error-message br+br {
  margin-top: 25px;
}

.Careers .email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.Careers .email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.Careers .email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}

.Careers .email-form input,
.Careers .email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
}

.Careers .email-form input:focus,
.Careers .email-form textarea:focus {
  border-color: #e96b56;
}

.Careers .email-form input {
  padding: 10px 15px;
}

.Careers .email-form textarea {
  padding: 12px 15px;
}

.Careers .email-form button[type=submit] {
  background: #e96b56;
  border: 0;
  border-radius: 50px;
  padding: 10px 24px;
  color: #fff;
  transition: 0.4s;
}

.Careers .email-form button[type=submit]:hover {
  background: #e6573f;
}

@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

#locationMap {
  width: 70%;
}

@media (max-width: 768px) {
  #locationMap {

    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  #googleMapbtn {
    display: block;
    margin-top: -10%;
    margin-bottom: 5%;
    border: 0;
    border-radius: 50px;
    padding: 10px 24px;
    color: #fff;
    transition: 0.4s;
    background: #d1095e;
    width: 70% !important;

  }
}

#googleMapbtn {

  display: block;
  width: 40%;
  margin-left: auto;
  margin-right: auto;
  margin-left: 14%;
  border: 0;
  border-radius: 50px;
  padding: 10px 24px;
  color: #fff;
  transition: 0.4s;
  background: #d1095e;
}