/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
  /* margin-top: -2%; */
  width: 100%;
  height: 70vh;
  margin-top: 5%;
}

#hero p {
  margin: 0 0 10px 0;
  font-size: 47px;
  font-weight: 700;
  line-height: 56px;
  color: black;
}

#hero h2 {
  color: #484848;
  margin-bottom: 50px;
  font-size: 24px;
}

#hero .btn-get-started {
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 30px;
  border-radius: 50px;
  transition: 0.5s;
  color: #3498db;
  border: 2px solid #3498db;
}

#hero .btn-get-started:hover {
  background: #3498db;
  color: #fff;
}

#hero .animated {
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
}

@media (min-width: 1024px) {
  #hero {
    background-attachment: fixed;
  }
}

@media (max-width: 991px) {
  #hero {
    height: 100vh;
  }

  #hero .animated {
    -webkit-animation: none;
    animation: none;
  }

  #hero .hero-img {
    text-align: center;
  }

  #hero .hero-img img {
    width: 50%;
  }
}

@media (max-width: 768px) {
  #hero {
    margin-top: 20px;
  }

  #hero h1 {
    font-size: 28px;
    line-height: 36px;
  }

  #hero h2 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 30px;
  }

  #hero .hero-img img {
    width: 70%;
  }
}

@media (max-width: 575px) {
  #hero .hero-img img {
    width: 80%;
  }
}

@-webkit-keyframes up-down {
  0% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(-10px);
  }
}

@keyframes up-down {
  0% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(-10px);
  }
}

/*--------------------------------------------------------------
# sponsor
--------------------------------------------------------------*/


@media (max-width: 991.98px) {
  .sponsor.col-md-3.col-xs-3.col-3 {
    padding: 0 !important;
  }
  
  #cardlogo{
    
    margin-top: -35% !important;
  }
  .sponsor.col-md-3.col-xs-3.col-3 img {
    margin: 0 !important;
  }
}

@media (max-width: 991.98px) {
  .sponsor.sponsor-color .column .container .row img {
    margin: 0 !important;
  }
}

@media (max-width: 991.98px) {
  body .sponsor.col-md-3 {
    text-align: center;
  }

  body .sponsor.col-md-3:nth-child(even) {
    position: relative;
    right: 15px;
  }
}

@media (min-width: 991.98px) {
  .sponsor {
    transform: translateY(-50%);
  }
}

.sponsor h2 {
  text-align: center;
  color: #333333;
}

@media (min-width: 1199.98px) {
  .sponsor h2 {
    width: 65%;
    margin: 0 auto;
  }
}

@media (max-width: 991.98px) {
  .sponsor h2 {
    font-size: 1.25em;
  }
}

.sponsor .column .container-fluid {
  max-width: 90%;
}

.sponsor .column .container-fluid .row {
  align-items: center;
}

.sponsor .column .container-fluid .row img {
  display: block;
  margin: 0 auto;
  max-width: 80%;
  max-height: 100px;
  transition: .2s;
}

.sponsor .column .container-fluid .row img:hover {
  filter: brightness(0);
  transform: scale(1.05);
}

@media (max-width: 767.98px) {
  .sponsor .column .container-fluid .row img {
    margin: 1em auto;
    max-height: 75px;
  }
}

@media (min-width: 2999px) and (max-width: 3999px) {
  .sponsor .column .container .sponsor img {
    width: 100%;
  }
}

@media (min-width: 4000px) and (max-width: 8000px) {
  .sponsor .column .container .sponsor img {
    width: 100%;
  }
}

.sponsor.sponsor-color {
  padding: 2.5vw 0;
}

.sponsor.sponsor-color .sponsor {
  transform: translateY(0) !important;
}

@media (min-width: 991.98px) {
  .sponsor.sponsor-color {
    transform: translateY(0) !important;
    margin: 0;
  }
}

.sponsor.sponsor-color .column .container .row .sponsor {
  margin: 1em auto;
}

.sponsor.sponsor-color .column .container .row img:hover {
  filter: inherit !important;
}

@media (max-width: 767.98px) {
  .sponsor.sponsor-color .column .container .row img {
    padding: 0 !important;
    margin: .5em auto;
    max-width: 100% !important;
    max-height: 40px;
  }
}

/*--------------------------------------------------------------
# ser
--------------------------------------------------------------*/
.ser .icon-box {
  padding: 25px;
  position: relative;
  overflow: hidden;
  background: #fff;
  box-shadow: 0 0 29px 0 rgba(68, 88, 144, 0.12);
  transition: all 0.3s ease-in-out;
  border-radius: 8px;
  z-index: 0;
}

.ser .icon-box::before {
  content: "";
  position: absolute;
  background: #e1f0fa;
  right: -60px;
  top: -40px;
  width: 100px;
  height: 100px;
  border-radius: 50px;
  transition: all 0.3s;
  z-index: -1;
}

.ser .icon-box:hover::before {
  background: #3498db;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 0px;
}

.ser .icon {
  margin: 0 auto 20px auto;
  padding-top: 10px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  background: #3498db;
  transition: all 0.3s ease-in-out;
}

.ser .icon i {
  font-size: 36px;
  line-height: 1;
  color: #fff;
}

.ser .title {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 18px;
}

.ser .title a {
  color: #111;
}

.ser .description {
  font-size: 15px;
  line-height: 28px;
  margin-bottom: 0;
}

.ser .icon-box:hover .title a,
.ser .icon-box:hover .description {
  color: #fff;
}

.ser .icon-box:hover .icon {
  background: #fff;
}

.ser .icon-box:hover .icon i {
  color: #3498db;
}

/*--------------------------------------------------------------
  # More ser
  --------------------------------------------------------------*/
.more-ser {
  padding-top: 20px;
}

.more-ser .card {
  border: 0;
  padding: 160px 20px 20px 20px;
  position: relative;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.more-ser .card-body {
  z-index: 10;
  background: rgba(255, 255, 255, 0.9);
  padding: 15px 30px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  transition: 0.3s;
  transition: ease-in-out 0.4s;
  border-radius: 5px;
}

.more-ser .card-title {
  font-weight: 700;
  text-align: center;
  margin-bottom: 15px;
}

.more-ser .card-title a {
  color: #222222;
}

.more-ser .card-text {
  color: #5e5e5e;
}

.more-ser .read-more a {
  color: #777777;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  transition: 0.4s;
}

.more-ser .read-more a:hover {
  text-decoration: underline;
}

.more-ser .card:hover .card-body {
  background: #3498db;
}

.more-ser .card:hover .read-more a,
.more-ser .card:hover .card-title,
.more-ser .card:hover .card-title a,
.more-ser .card:hover .card-text {
  color: #fff;
}

/*--------------------------------------------------------------
# Counts
--------------------------------------------------------------*/
.counts {
  padding-top: 0;
}

.counts .content {
  padding: 0;
}

.counts .content h3 {
  font-weight: 700;
  font-size: 34px;
  color: #222222;
}

.counts .content p {
  margin-bottom: 0;
}

.counts .content .count-box {
  padding: 20px 0;
  width: 100%;
}

.counts .content .count-box i {
  display: block;
  font-size: 36px;
  color: #3498db;
  float: left;
  line-height: 0;
}

.counts .content .count-box span {
  font-size: 36px;
  line-height: 30px;
  display: block;
  font-weight: 700;
  color: #222222;
  margin-left: 50px;
}

.counts .content .count-box p {
  padding: 15px 0 0 0;
  margin: 0 0 0 50px;
  font-family: "Raleway", sans-serif;
  font-size: 14px;
  color: #484848;
}

.counts .content .count-box a {
  font-weight: 600;
  display: block;
  margin-top: 20px;
  color: #484848;
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  transition: ease-in-out 0.3s;
}

.counts .content .count-box a:hover {
  color: #6f6f6f;
}

@media (max-width: 1024px) {
  .counts .image {
    text-align: center;
  }

  .counts .image img {
    max-width: 70%;
  }
}

@media (max-width: 667px) {
  .counts .image img {
    max-width: 100%;
  }
}

@media (max-width: 667px) {
  #countNum {
    /* max-width: 100%; */
    /* margin: auto;
    width: 50%;
    border: 3px solid green;
    padding: 10px; */
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}


#labtopImage {
  margin-left: 10%;
}

#countNum {
  margin-left: 35%;
}